<template>
  <div>
    <template v-if="catalogos_cargados">
    <fieldset>
      <legend>Avaluo</legend>

      <table>
        <thead>
          <tr>
            <th>Inicidencia</th>
            <th>Detalles</th>
            <th>Fecha registro</th>
            <th>Fecha resuelto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="incidencias_avaluo.length == 0">
            <td colspan="4">Sin incidencias</td>
          </tr>
          <template>
          <tr v-for="incidencia in incidencias_avaluo" :key="incidencia.id">
            <td>{{ buscar_nombre(incidencia.subtipo) }}</td>
            <td>{{ incidencia.descripcion }}</td>
            <td>{{ $moment(incidencia.created_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
            <td>{{ $moment(incidencia.resolucion_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
          </tr>
          </template>
        </tbody>
      </table>
    </fieldset>

    <fieldset>
      <legend>Dictamen garantía</legend>

      <table>
        <thead>
          <tr>
            <th>Inicidencia</th>
            <th>Detalles</th>
            <th>Fecha registro</th>
            <th>Fecha resuelto</th>
          </tr>
        </thead>
        <tbody>
        <tr v-if="incidencias_inmueble.length == 0">
          <td colspan="4">Sin incidencias</td>
        </tr>
        <template v-else>
          <tr v-for="incidencia in incidencias_inmueble" :key="incidencia.id">
            <td>{{ buscar_nombre(incidencia.subtipo) }}</td>
            <td>{{ incidencia.descripcion }}</td>
            <td>{{ $moment(incidencia.created_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
            <td>{{ $moment(incidencia.resolucion_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </fieldset>

    <fieldset>
      <legend>Dictamen cliente</legend>

      <table>
        <thead>
          <tr>
            <th>Inicidencia</th>
            <th>Detalles</th>
            <th>Fecha registro</th>
            <th>Fecha resuelto</th>
          </tr>
        </thead>
        <tbody>
        <tr v-if="incidencias_cliente.length == 0">
          <td colspan="4">Sin incidencias</td>
        </tr>
        <template v-else>
          <tr v-for="incidencia in incidencias_cliente" :key="incidencia.id">
            <td>{{ buscar_nombre(incidencia.subtipo) }}</td>
            <td>{{ incidencia.descripcion }}</td>
            <td>{{ $moment(incidencia.created_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
            <td>{{ $moment(incidencia.resolucion_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </fieldset>
    
    <fieldset>
      <legend>Dictamen CLG</legend>

      <div class="row fecha_caducidad mb-4">
        <div for="" class="col-sm-2"><div class="label">Fecha caducidad</div></div>
        <div class="col-sm-3 bloque_info">
          <div class="fecha">{{ solicitud.caducidad_clg_at ? $moment(solicitud.caducidad_clg_at).format('dddd DD MMMM YYYY') : 'N/A' }}</div>
        </div>
        <div :class="(solicitud.caducidad_clg_at ? (clg_vigente ? 'bloque_success' : 'bloque_danger') : 'bloque_info')+' col-sm-3'">
          <div>{{ solicitud.caducidad_clg_at ? (clg_vigente ? 'Aún vigente' : 'Caducado') : 'N/A' }}</div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Inicidencia</th>
            <th>Detalles</th>
            <th>Fecha registro</th>
            <th>Fecha resuelto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="incidencias_clg.length == 0">
            <td colspan="4">Sin incidencias</td>
          </tr>
          <template v-else>
          <tr v-for="incidencia in incidencias_clg" :key="incidencia.id">
            <td>{{ buscar_nombre(incidencia.subtipo) }}</td>
            <td>{{ incidencia.descripcion }}</td>
            <td>{{ $moment(incidencia.created_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
            <td>{{ $moment(incidencia.resolucion_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </fieldset>
    </template>
  </div>
</template>

<script type="text/javascript">
  import apiCatalogos from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            incidencias: []
          }
        }
      }
    }
    ,data() {
      return {
        opciones: []
        ,catalogos_cargados: false
      }
    }
    ,mounted() {
      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_catalogos() {
        try {
          this.opciones = await this.$helper.cache_store('formalizacion.catalogos.incidencias_all',async () => {
            let valuacion = (await apiCatalogos.buscar_catalogo('incidencias_valuacion')).data;
            let clientes = (await apiCatalogos.buscar_catalogo('incidencias_cliente')).data;
            let inmuebles = (await apiCatalogos.buscar_catalogo('incidencias_inmueble')).data;
            let clg = (await apiCatalogos.buscar_catalogo('incidencias_clg')).data;

            let opciones = []
            opciones = opciones.concat(valuacion.opciones);
            opciones = opciones.concat(clientes.opciones);
            opciones = opciones.concat(inmuebles.opciones);
            opciones = opciones.concat(clg.opciones);

            return opciones;
          });

          this.catalogos_cargados = true;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_nombre(tipo) {
        let nombre;

        this.opciones.forEach(opcion => {
          if (opcion.valor == tipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
    }
    ,computed: {
      incidencias_avaluo() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'avaluo')
            incidencias.push(incidencia);
        })

        return incidencias;
      }
      ,incidencias_cliente() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'dictamen_cliente')
            incidencias.push(incidencia);
        })

        return incidencias;
      }
      ,incidencias_inmueble() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'dictamen_inmueble')
            incidencias.push(incidencia);
        })

        return incidencias;
      }
      ,incidencias_clg() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'dictamen_clg')
            incidencias.push(incidencia);
        })

        return incidencias;
      }
      ,clg_vigente() {
        return this.$moment(this.solicitud.caducidad_clg_at).isAfter();
      }
    }
  }
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    border: solid 1px #4C4C4C;
    margin-bottom: 20px;

    thead {
      tr {
        background-color: #4C4C4C;
        color: #fff;

        th {
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #4C4C4C;

        td {
          padding: 5px 10px;
        }

        td:nth-child(1) {
          width: 20%;
        }

        td:nth-child(2) {
          width: 40%;
        }
      }

      tr:last-child {
        border: 0px;
      }

      // tr:nth-child(2n+0) {
      //   background-color: #E6E6E6
      // }
    }
  }

  .fecha_caducidad {

    .label {
      background-color: #4C4C4C;
      color: #fff;
      padding: 5px 10px;
    }

    .bloque_info, .bloque_success, .bloque_danger {
      div {
        background-color: #999999;
        color: #fff;
        padding: 5px 10px;
      }
    }

    .bloque_success {
      div {
        background-color: #28a745;
      }
    }

    .bloque_danger {
      div {
        background-color: #dc3545;
      }
    }
  }
</style>