<template>
  <div>
    <div class="row">
      <div class="col-sm-6 offset-sm-3">

        <form @submit.prevent="guardarEstatus">
          <div class="row form-group">
            <label for="estatus_pago_comision" class="col-form-label col-sm-12"><strong>Estatus pago comision de avaluo</strong> </label>
            <div class="col-sm-12">
              <select v-model="estatus_pago_comision_avaluo" name="estatus_pago_comision_avaluo" id="estatus_pago_comision_avaluo" class="form-control">
                <option v-for="opcion in catalogo_estatus_pago_comisiones.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
              </select>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-sm-12 text-right"><button class="btn btn-primary">Guardar estatus</button></div>
          </div>
        </form>

        <hr>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Monto avalúo</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">$</span>
              </div>
              <input v-model="costo_avaluo" type="number" class="form-control" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" readonly="readonly">
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Monto complementario</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">$</span>
              </div>
              <input v-model="monto_complementario" type="number" class="form-control" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" readonly="readonly">
            </div>
          </div>
        </div>

        <hr>

          <div class="row form-group">
            <label for="monto_total" class="col-form-label col-sm-3">Costo total Avalúo</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">$</span>
                </div>
                <input v-model="monto_total" type="text" class="form-control" name="monto_total" id="monto_total" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" readonly="readonly">
              </div>
            </div>
          </div>
          
          <div class="row form-group">
            <label for="monto_comision" class="col-form-label col-sm-3">Monto de comisión</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">$</span>
                </div>
                <input v-model="monto_comision" type="text" class="form-control" name="monto_comision" id="monto_comision" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" readonly="readonly">
              </div>
            </div>
          </div>

        <hr>

        <form v-if="!solicitud.carga_factura_at" @submit.prevent="cagar_factura">
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-3">Factura XML</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <input v-model="nombre_archivo_xml" type="text" class="form-control" placeholder="Factura XML..." aria-label="Recipient's username" aria-describedby="button-addon2" readonly="readonly">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="seleccionar_archivo('xml')">Seleccionar factura</button>
                </div>
              </div>
              <input style="display: none;" type="file" name="seleccionar_archivo_xml" id="seleccionar_archivo_xml" ref="seleccionar_archivo_xml" @change="ver_nombre_archivo" accept=".xml">
            </div>
          </div><div class="row form-group">
            <label for="" class="col-form-label col-sm-3">Factura PDF</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <input v-model="nombre_archivo_pdf" type="text" class="form-control" placeholder="Factura PDF..." aria-label="Recipient's username" aria-describedby="button-addon2" readonly="readonly">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="seleccionar_archivo('pdf')">Seleccionar factura</button>
                </div>
              </div>
              <input style="display: none;" type="file" name="seleccionar_archivo_pdf" id="seleccionar_archivo_pdf" ref="seleccionar_archivo_pdf" @change="ver_nombre_archivo" accept=".pdf">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-sm-12 text-right"><button class="btn btn-primary">Cargar factura</button></div>
          </div>
        </form>

        <div v-else class="row form-group">
          <label for="" class="col-form-label col-sm-3">Factura para pago</label>
          <div class="col-sm-9">
            <div class="form-control">Factura carga el día {{ $moment(solicitud.carga_factura_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</div>
          </div>
        </div>

        <hr>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Fecha de pago de factura BpB</label>
          <div class="col-sm-9">
            <div class="form-control">
              <span  v-if="solicitud.fecha_pago_factura_bpb"> {{ $moment(solicitud.fecha_pago_factura_bpb).format('dddd DD MMMM YYYY') }}</span>
              <span v-else>El valuador aún no ingresa la fecha de pago</span>
            </div>
          </div>
        </div>
        <hr>

          <div class="direccion">
            <div class="row form-group">
              <label for="calle" class="col-form-label col-sm-3">Calle</label>
              <div class="col-sm-9"><input v-model="direccion.calle" type="text" name="calle" id="calle" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="numero_exterior" class="col-form-label col-sm-3">Número exterior</label>
              <div class="col-sm-9"><input v-model="direccion.numero_exterior" type="text" name="numero_exterior" id="numero_exterior" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="numero_interior" class="col-form-label col-sm-3">Número interior <small>(opcional)</small></label>
              <div class="col-sm-9"><input v-model="direccion.numero_interior" type="text" name="numero_interior" id="numero_interior" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="colonia" class="col-form-label col-sm-3">Colonia</label>
              <div class="col-sm-9"><input v-model="direccion.colonia" type="text" name="colonia" id="colonia" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="delegacion" class="col-form-label col-sm-3">Delegación / Municipio</label>
              <div class="col-sm-9"><input v-model="direccion.delegacion" type="text" name="delegacion" id="delegacion" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="ciudad" class="col-form-label col-sm-3">Ciudad <small>(opcional)</small></label>
              <div class="col-sm-9"><input v-model="direccion.ciudad" type="text" name="ciudad" id="ciudad" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="estado" class="col-form-label col-sm-3">Estado</label>
              <div class="col-sm-9"><input v-model="direccion.estado" type="text" name="estado" id="estado" class="form-control" readonly="true"></div>
            </div>
            <div class="row form-group">
              <label for="cp" class="col-form-label col-sm-3">CP</label>
              <div class="col-sm-9"><input v-model="direccion.cp" type="text" name="cp" id="cp" class="form-control" readonly="true"></div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiValija from '@/apps/formalizacion/api/v2/valija'
  import apiCatalogos from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            solicitud: 0
            ,costo_avaluo: 0
            ,monto_complementario: 0
            ,monto_total: 0
            ,monto_comision: 0
          }
        }
      }
    }
    ,data() {
      return {
        nombre_archivo_xml: null
        ,nombre_archivo_pdf: null
        ,costo_avaluo: 0
        ,monto_complementario: 0
        ,monto_total: 0
        ,monto_comision: 0
        ,tipo_archivo: 'xml'
        ,direccion: {
          calle: null
          ,numero_exterior: null
          ,numero_interior: null
          ,colonia: null
          ,delegacion: null
          ,ciudad: null
          ,estado: null
          ,cp: null
        }
        ,estatus_pago_comision_avaluo: null
        ,catalogo_estatus_pago_comisiones: {
          opciones: []
        }
      }
    }
    ,mounted() {
      this.costo_avaluo = this.solicitud.costo_avaluo;
      this.monto_complementario = this.solicitud.monto_complementario;
      this.monto_total = this.solicitud.monto_total;
      this.monto_comision = this.solicitud.monto_comision;
      this.estatus_pago_comision_avaluo = this.solicitud.estatus_pago_comision_avaluo;

      if (this.solicitud.inmuebles.length > 0)
        this.direccion = this.solicitud.inmuebles[0];

      this.cargar_catalogo_estatus_pago_comision();
    }
    ,methods: {
      ver_nombre_archivo() {
        if (this.tipo_archivo == 'xml') {
          if (this.$refs.seleccionar_archivo_xml.files.length == 0)
            return;

          this.nombre_archivo_xml = this.$refs.seleccionar_archivo_xml.files[0].name
        }else {
          if (this.$refs.seleccionar_archivo_pdf.files.length == 0)
            return;

          this.nombre_archivo_pdf = this.$refs.seleccionar_archivo_pdf.files[0].name
        }
      }
      ,seleccionar_archivo(tipo) {
        this.$log.info('costo_avaluo', this.costo_avaluo)
        if (!this.costo_avaluo || !this.monto_complementario || !this.monto_total || !this.monto_comision)
          return this.$helper.showMessage('Error','Es necesario que este definido el costo del avalúo y el monto complementario para poder subir la factura','error','alert');

        this.tipo_archivo = tipo;

        if (tipo == 'xml')
          this.$refs.seleccionar_archivo_xml.click();
        else
          this.$refs.seleccionar_archivo_pdf.click();
      }
      ,async cagar_factura() {
        try {
          if (this.$refs.seleccionar_archivo_xml.files.length == 0 || this.$refs.seleccionar_archivo_pdf.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar el archivo XML y el PDF','error','alert');

          let payload = new FormData();
          payload.append('archivos[0][archivo]', this.$refs.seleccionar_archivo_xml.files[0]);
          payload.append('archivos[0][tipo]', 'factura_avaluo_xml');
          payload.append('archivos[1][archivo]', this.$refs.seleccionar_archivo_pdf.files[0]);
          payload.append('archivos[1][tipo]', 'factura_avaluo_pdf');

          let res = (await apiSolicitud.cargar_documentos(this.solicitud.id, payload)).data;
          this.$log.info('res', res);

          res = (await apiSolicitud.editar_solicitud(this.solicitud.id, {carga_factura: true})).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async cargar_catalogo_estatus_pago_comision() {
        try {
          this.catalogo_estatus_pago_comisiones = await this.$helper.cache_store('formalizacion.catalogos.estatus_pago_comisiones', async () => {
            return (await apiCatalogos.buscar_catalogo('estatus_pago_comisiones')).data;
          });

        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async guardarEstatus() {
        try {

          let payload_editar_solicitud = {
            estatus_pago_comision_avaluo: this.estatus_pago_comision_avaluo
          }
          let res = (await apiSolicitud.editar_solicitud(this.solicitud.id, payload_editar_solicitud)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>