<template>
  <div :class="'row'+(css_class ? ' '+css_class : '')">
    <div class="col-sm-12 pasos_bloque">
      <div class="paso title">
        <span class="text">{{ title }}</span>
        <div class="punta"></div>
      </div>
      <div v-for="(paso,index) in pasos" :key="index" :class="'paso'+(paso.valor == activo ? ' activo' : (index < get_index(activo) ? ' inactivo' : ''))">
        <span class="text" :title="paso.texto">{{ paso.texto }}</span>
        <div class="cola"></div>
        <div class="punta"></div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      pasos: {
        type: Array
        ,default() {
          return [
            {
              texto: "Prueba de texto"
              ,valor: 1
            }
            ,{
              texto: "Prueba de texto"
              ,valor: 2
            }
            ,{
              texto: "Prueba de texto"
              ,valor: 3
            }
            ,{
              texto: "Prueba de texto"
              ,valor: 4
            }
            ,{
              texto: "Prueba de texto"
              ,valor: 5
            }
          ];
        }
      }
      ,activo: {
        type: String
        ,default: 3
      }
      ,css_class: null
      ,title: null
    }
    ,data() {
      return {
        ancho_calculado: false
      }
    }
    ,updated() {
      this.actualizar_ancho_maximo();
    }
    ,methods: {
      get_index: function(etapa) {
        let index = 0;

        for(let i=0; i<this.pasos.length; i++) {
          if (this.pasos[i].valor == etapa)
            index = i;
        }

        this.prueba = 2;

        return index;
      }
      ,actualizar_ancho_maximo() {
        if (this.ancho_calculado)
          return;

        this.$log.info('actualizar anchos');
        let etapas = document.querySelectorAll('.pasos_bloque .paso .text');
        console.log('etapas',etapas)
        let espacio_usado = 0;

        etapas.forEach(etapa => {
          espacio_usado += etapa.clientWidth;
        })

        let separaciones = 15 * (etapas.length);
        let espacio = document.querySelector('.pasos_bloque').clientWidth;

        if (espacio_usado >= espacio)
          espacio = (espacio - separaciones) - 10;

        let espacio_maximo = Math.ceil(espacio / (etapas.length));

        this.$log.info('ancho maximo: '+espacio_maximo);

        etapas.forEach(etapa => {
          etapa.style.maxWidth = espacio_maximo+'px';
        })

        this.ancho_calculado = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  $margin: 15px;

  .pasos_bloque {
    $color_bloque: #2A70C6;
    border-top: solid 1px $color_bloque;
    border-bottom: solid 1px $color_bloque;
    padding: 5px;
    margin: 5px;
    max-width: 99%;
    display: inline-block;

    .paso {
      $color: #666666;
      font-size: 0.8em;
      display:inline-block;
      cursor: default;
      position: relative;

      span.text {
        // max-width: 130px;
        background-color: $color;
        color: #fff;
        padding: 5px 10px;
        padding-left: 25px;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .cola {
        width: 0px;
        height: 0px;
        border-top: $margin solid transparent;
        border-bottom: $margin solid transparent;
        border-left: $margin solid #fff;
        display: inline-block;
        position: absolute;
        left: 0px;
        z-index: 100;
      }

      .punta {
        width: 0px;
        height: 0px;
        border-top: $margin solid transparent;
        border-bottom: $margin solid transparent;
        border-left: $margin solid $color;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .title {
      span.text {
        background-color: $color_bloque;
        padding-left: 10px;
      }

      .punta {
        border-left: $margin solid $color_bloque;
      }
    }

    .activo {
      // $color: #2A70C6;
      $color: #388B23;

      .text {
        background-color: $color !important;
      }

      .punta {
        border-left: $margin solid  $color;
      }
    }

    .inactivo {
      $color: #C2C2C2;

      .text {
        background-color: $color !important;
        color: #8E8E8E !important;
      }

      .punta {
        border-left: $margin solid  $color;
      }
    }
  }
</style>