<template>
  <div>
    <div class="row">
      <div class="col-sm-9 offset-sm-2">

        <div class="row form-group">
          <label for="fondeador" class="col-form-label col-sm-3">Fondeador</label>
          <div class="col-sm-9">
            <select v-if="solicitud.fondeador" v-model="solicitud.fondeador" name="fondeador" id="fondeador" class="form-control" readonly disabled>
              <option v-for="opcion in catalogo_fondeadores.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
            <div v-else class="form-control">
              <span>No definido aún</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label for="tipo_comision_fondeador" class="col-form-label col-sm-3">Tipo de comisión</label>
          <div class="col-sm-9">
            <select v-model="tipo_comision_fondeador" name="tipo_comision_fondeador" id="tipo_comision_fondeador" class="form-control" :disabled="fondeador_sin_comision">
              <option v-for="opcion in catalogo_tipo_comision.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Monto de comisión</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">$</span>
              </div>
              <input v-model="monto_comision_fondeador" type="number" class="form-control" placeholder="0.00" aria-label="Monto de comisión" aria-describedby="basic-addon1" :disabled="fondeador_sin_comision">
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Fecha envio factura </label>
          <div class="col-sm-9">
            <div class="form-control">
              <span  v-if="solicitud.carga_factura_at"> Factura carga el día {{ $moment(solicitud.carga_factura_at).format('dddd DD MMMM YYYY HH:mm:ss') }} </span>
              <span v-else>No definido aún</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label for="fecha_pago_comision_fondeador" class="col-form-label col-sm-3">Fecha pago de comisión</label>
          <div class="col-sm-9"><input v-model="fecha_pago_comision_fondeador" type="date" name="fecha_pago_comision_fondeador" id="fecha_pago_comision_fondeador" class="form-control" :disabled="fondeador_sin_comision"></div>
        </div>

        <div class="row form-group">
          <label for="estatus_pago_comision" class="col-form-label col-sm-3">Estatus pago comision</label>
          <div class="col-sm-9">
            <select v-model="estatus_pago_comision_fondeador" name="estatus_pago_comision" id="estatus_pago_comision" class="form-control" :disabled="fondeador_sin_comision">
              <option v-for="opcion in catalogo_estatus_pago_comisiones.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <div v-if="!fondeador_sin_comision" class="col-sm-12 text-right"><button class="btn btn-primary" @click="editar">Guardar cambios</button></div>
          <span v-else class="text-danger">* El fondeador asignado no recibe comisiones.</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogos from '@/apps/formalizacion/api/v2/catalogos'


  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            solicitud: 0
            ,monto_comision_fondeador: 0
          }
        }
      }
    }
    ,data() {
      return {
        carga_factura_at: null
        ,tipo_comision_fondeador: null
        ,estatus_pago_comision_fondeador: null
        ,monto_comision_fondeador: null
        ,fecha_pago_comision_fondeador: null
        ,catalogo_estatus_pago_comisiones: {
          opciones: []
        }
        ,catalogo_fondeadores: {
          opciones: []
        }
        ,catalogo_tipo_comision: {
          opciones: []
        }
      }
    }
    ,computed: {
      fondeador_sin_comision(){
        return this.solicitud.fondeador === 'bien_para_bien'
      }
    }
    ,mounted() {
      this.carga_factura_at = this.solicitud.carga_factura_at;
      this.tipo_comision_fondeador = this.solicitud.tipo_comision_fondeador;
      this.monto_comision_fondeador = this.solicitud.monto_comision_fondeador;
      this.estatus_pago_comision_fondeador = this.solicitud.estatus_pago_comision_fondeador;
      this.fecha_pago_comision_fondeador = this.solicitud.fecha_pago_comision_fondeador;

      this.cargar_catalogos()
    }
    ,methods: {
      async cargar_catalogos() {
        try {
          this.catalogo_fondeadores = await this.$helper.cache_store('formalizacion.catalogos.fondeadores', async () => {
            return (await apiCatalogos.buscar_catalogo('fondeadores')).data;
          });

          this.catalogo_estatus_pago_comisiones = await this.$helper.cache_store('formalizacion.catalogos.estatus_pago_comisiones', async () => {
            return (await apiCatalogos.buscar_catalogo('estatus_pago_comisiones')).data;
          });

          this.catalogo_tipo_comision = await this.$helper.cache_store('formalizacion.catalogos.tipo_comision', async () => {
            return (await apiCatalogos.buscar_catalogo('tipo_comision')).data;
          });

        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      async editar() {
        try {

          if(!this.solicitud.fondeador){
            this.$helper.showMessage('Comisiones fondeador','No se guardaron los cambios, es necesario primero asignar un fondeador en la sección de "Info"','error','alert');
            return;
          }

          let payload_editar_solicitud = {
            tipo_comision_fondeador: this.tipo_comision_fondeador,
            monto_comision_fondeador: this.monto_comision_fondeador,
            estatus_pago_comision_fondeador: this.estatus_pago_comision_fondeador
          }

          if(this.fecha_pago_comision_fondeador){
            payload_editar_solicitud['fecha_pago_comision_fondeador'] = this.fecha_pago_comision_fondeador
          }

          let res = (await apiSolicitud.editar_solicitud(this.solicitud.id, payload_editar_solicitud)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>