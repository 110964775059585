<template>
  <div>
    <div class="row mt-4 mb-4">
      <h1 class="col-sm-12 text-right">Etapas</h1>
    </div>

    <div class="row mb-4">
      <div class="col-sm-12">
        <button class="btn btn-warning mr-2" @click="obtener_etapas">Recargar</button>
        <button class="btn btn-primary mr-2" @click="modal_nuevo=true">Crear etapa</button>
        <button class="btn btn-info mr-2" @click="guardar_cambios">Guardar cambios</button>
        <div class="btn-group" role="group">
          <button id="grpo_estatus" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Ver etapas de usuario "{{ nombre_tipo_usuario(tipo_usuario) }}"
          </button>
          <div class="dropdown-menu" aria-labelledby="grpo_estatus">
            <a class="dropdown-item" href="#" @click.prevent="tipo_usuario=0">Sistema</a>
            <a class="dropdown-item" href="#" @click.prevent="tipo_usuario=1">Valuador</a>
            <a class="dropdown-item" href="#" @click.prevent="tipo_usuario=2">Notario</a>
            <!-- <a class="dropdown-item" href="#" @click.prevent="tipo_usuario=3">Administrador</a> -->
          </div>
        </div>
      </div>
    </div>

    <table class="etapas">
      <thead>
        <tr>
          <th>Subir / Bajar</th>
          <th>Etapa</th>
          <th>Código</th>
          <th>Descripción</th>
          <th>Posición</th>
          <th>Final</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(etapa, index) in etapas_mostrar" :key="index">
          <td>
            <button class="btn btn-primary mr-2" :disabled="index <= 0" @click="subir_etapa(index)">Subir</button>
            <button class="btn btn-danger" :disabled="index >= etapas_mostrar.length-1" @click="bajar_etapa(index)">Bajar</button>
          </td>
          <td>{{ etapa.etapa }}</td>
          <td>{{ etapa.codigo }}</td>
          <td>{{ etapa.descripcion }}</td>
          <td>{{ etapa.posicion }}</td>
          <td>{{ etapa.etapa_final == 1 ? 'Sí' : 'No' }}</td>
          <td>
            <img class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar archivo" title="Editar archivo" @click="editar_etapa(etapa, index)">
            <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar archivo" title="Elimitar archivo" @click="quitar_etapa(etapa,index)">
          </td>
        </tr>
      </tbody>
    </table>

    <Nuevo v-if="modal_nuevo" :tipo="tipo_usuario" :etapa="etapa_editar" :etapas="etapas" @nEtapa="agregar_etapa" @update="obtener_etapas" @close="cancelar_modal_nuevo" />
  </div>
</template>

<script type="text/javascript">
  import Nuevo from './Etapa/Nuevo'

  import apiEtapas from '@/apps/formalizacion/api/v2/etapas'

  export default {
    components: {
      Nuevo
    }
    ,data() {
      return {
        tipo_usuario: null
        ,etapas: []
        ,etapas_mostrar: []
        ,sin_asignar: true
        ,modal_nuevo: false
        ,etapa_editar: {
          id: null
        }
        ,etapa_editar_index: null
        ,etapas_eliminar: []
        ,etapas_modificadas: false
      }
    }
    ,mounted() {
      this.obtener_etapas();
    }
    ,methods: {
      async obtener_etapas() {
        try {
          this.etapas = (await apiEtapas.obtener_etapas()).data
          this.tipo_usuario = 2;
          this.etapas_eliminar = [];
          this.ordenar_etapas();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,ordenar_etapas() {
        let etapas = [];

        for(let i=0; i<this.etapas.length; i++) {
          if (this.etapas[i].tipo_usuario == this.tipo_usuario) {
            this.etapas[i].index_org = i;
            etapas.push(this.etapas[i]);
          }
        }

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        this.etapas_mostrar = etapas;
      }
      ,nombre_tipo_usuario(tipo) {
        switch(tipo) {
          case 0:
            return 'Sistema';
            break;
          case 1:
            return 'Valuador';
            break;
          case 2:
            return 'Notario';
            break;
          case 3:
            return 'Administrador';
            break;
        }
      }
      ,subir_etapa(index, posicion) {
        if (index <= 0)
          return this.$helper.showMessage('Error','No se puede subir de posición','error','alert');
        
        let etapas = this.etapas_mostrar;

        etapas[index].posicion -= 1;
        etapas[index-1].posicion += 1;

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        this.etapas_mostrar = etapas;
        this.etapas_modificadas = true;
      }
      ,bajar_etapa(index) {
        if (index >= this.etapas_mostrar.length-1)
          return this.$helper.showMessage('Error','No se puede bajar de posición','error','alert');
        
        let etapas = this.etapas_mostrar;

        etapas[index].posicion += 1;
        etapas[index+1].posicion -= 1;

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        this.etapas_mostrar = etapas;
        this.etapas_modificadas = true;
      }
      ,async guardar_cambios() {
        try {

          if (this.etapas_modificadas) {
            for(let i=0; i<this.etapas_mostrar.length; i++) {
              let res;

              if (!this.etapas_mostrar[i].id)
                res = (await apiEtapas.crear_etapa(this.etapas_mostrar[i])).data
              else
                res = (await apiEtapas.editar_etapa(this.etapas_mostrar[i].id, this.etapas_mostrar[i])).data

              this.$log.info('res', res);
            }
          }

          if (this.etapas_eliminar.length > 0) {
            for(let i=0; i<this.etapas_eliminar.length; i++) {
              let res = (await apiEtapas.eliminar_etapa(this.etapas_eliminar[i].id)).data;
              this.$log.info('res', res)

              delete this.etapas_eliminar[i];
            }
          }

        this.etapas_modificadas = false;
        this.etapa_editar_index = null;
        this.etapas_eliminar = [];

        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,editar_etapa(etapa, index) {
        this.etapa_editar_index = etapa.index_org;
        etapa.posicion_org = etapa.posicion;
        this.etapa_editar = etapa;
        this.modal_nuevo = true;
      }
      ,quitar_etapa(etapa, index) {
        let etapas = [];

        for(let i=0; i<this.etapas.length; i++) {
          if (this.etapas[i].tipo_usuario != etapa.tipo_usuario)
            etapas.push(this.etapas[i]);
          else {
            if (this.etapas[i].posicion != etapa.posicion) {
              if (this.etapas[i].posicion > etapa.posicion) {
                let tmp = this.etapas[i];
                tmp.posicion -= 1;
                etapas.push(tmp);
              }else {
                etapas.push(this.etapas[i]);
              }
            }
          }
        }

        if (etapa.id)
          this.etapas_eliminar.push(etapa);

        this.etapas = etapas;

        this.ordenar_etapas();
      }
      ,cancelar_modal_nuevo() {
        this.etapa_editar = { id: null };
        this.modal_nuevo = false;
        this.etapa_editar_index = null;
      }
      ,agregar_etapa(etapa) {
        
        if (!this.etapa_editar_index) {
          this.$log.info('sin index', etapa);
          let etapas = [];

          this.$log.info('total: '+this.etapas_mostrar.length)

          for(let i=0; i<this.etapas_mostrar.length; i++) {
            if (this.etapas_mostrar[i].tipo_usuario == etapa.tipo_usuario) {
              if (this.etapas_mostrar[i].posicion < etapa.posicion) {
                this.$log.info('index: a '+i);
                etapas.push(this.etapas_mostrar[i]);
              }else  if (this.etapas_mostrar[i].posicion > etapa.posicion) {
                this.$log.info('index: b '+i);
                let tmp = this.etapas_mostrar[i];
                tmp.posicion += 1;
                etapas.push(tmp);
              }else if (this.etapas_mostrar[i].posicion == etapa.posicion) {

                let tmp = this.etapas_mostrar[i];
                // tmp.posicion += 1;
                etapas.push(tmp);

                this.$log.info('index: c '+i);
                tmp = null;
                tmp = this.$helper.clone(etapa);
                tmp.posicion += 1;
                etapas.push(tmp);
              }
            }
          }

          this.$log.info('etapas',etapas);

          this.etapas_mostrar = etapas;

        }else {
          this.$log.info('con index');

          if (etapa.posicion_org > etapa.posicion)
            etapa.posicion += 1;

          let etapas = [];
          let tmpEtapas = this.$helper.clone(this.etapas);

          // this.$log.info('etapa.index_org', etapa.index_org);
          for(let i=0; i<tmpEtapas.length; i++) {
            if (tmpEtapas[i].tipo_usuario == this.tipo_usuario) {
              if (tmpEtapas[i].posicion >= etapa.posicion) {
                if (tmpEtapas[i].posicion < etapa.posicion_org) {
                  tmpEtapas[i].posicion += 1;
                }
              }

              if (tmpEtapas[i].posicion > etapa.posicion_org && tmpEtapas[i].posicion <= etapa.posicion) {
                tmpEtapas[i].posicion -= 1;
              }

              if ( i < etapa.index_org) {
                etapas.push(tmpEtapas[i]);
              }

              if (i == etapa.index_org) {
                this.$log.info('reemplazamos el index '+i);
                // etapa.posicion = 1;
                etapas.push(etapa);
              }

              if ( i > etapa.index_org) {
                this.$log.info('avanzamos en 1 el index '+i);
                let tmp = tmpEtapas[i];
                tmp.posicion += 2;
                etapas.push(tmp);
              }
            }else {
              this.$log.info('etapa de otro tipo de usuario')
              etapas.push(tmpEtapas[i]);
            }
          }

          this.$log.info('etapas', etapas);

          this.etapas = etapas;

          this.ordenar_etapas();
        }

        this.etapas_modificadas = true;

        this.cancelar_modal_nuevo();
      }
    }
    ,watch: {
      tipo_usuario() {
        this.ordenar_etapas();
      }
    }
  }
</script>

<style lang="scss">
  .etapas {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #4C4C4C !important;
          color: #fff;
          padding: 5px 10px;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px 0px;

          img {
            width: 20px;
          }
        }

        td:nth-child(1),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8) {
          text-align: center;
        }
      }

      tr:nth-child(2n+0) {
        background-color: #F3F3F3;
      }
    }

    .etapa {
      img {
        width: 20px;
      }
    }

    .etapa:nth-child(2n+0) {
      background-color: #F3F3F3
    }
  }
</style>