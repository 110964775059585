<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="row">
          <h1 class="col-sm-12">General</h1>
        </div>
      
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Asignación Notario</label>
          <div class="col-sm-10">
            <div class="form-control">{{ buscar_usuario(2) ? buscar_usuario(2).nombre : 'No asignado' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Asignación Valuador</label>
          <div class="col-sm-10">
            <div class="form-control">{{ buscar_usuario(1) ? buscar_usuario(1).nombre : 'No asignado' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Fecha de arribo</label>
          <div class="col-sm-10">
            <div class="form-control">{{ $moment(solicitud.created_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</div>
          </div>
        </div>
        <div class="row form-group">
          <label for="fondeador" class="col-form-label col-sm-2">Fecha a ingreso RPP</label>
          <div class="col-sm-10">
            <div class="form-control">{{ solicitud.firma_contrato_at ? solicitud.firma_contrato_at : 'No definido aún' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <label for="fondeador" class="col-form-label col-sm-2">Fecha de inscripción RPP</label>
          <div class="col-sm-10">
            <div class="form-control">{{ solicitud.incripcion_rpp_at ? solicitud.incripcion_rpp_at : 'No definido aún' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <label for="fondeador" class="col-form-label col-sm-2">Fecha de entrega a Bien para bien</label>
          <div class="col-sm-10">
            <div class="form-control">{{ solicitud.entrega_bpb_at ? solicitud.entrega_bpb_at : 'No definido aún' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Estatus</label>
          <div class="col-sm-10">
            <div class="form-control">{{ estatus() }}</div>
          </div>
        </div>

        <hr>

        <div class="row mb-3">
          <div class="col-sm-12 text-right"><button class="btn btn-primary" @click="guardar_fondeador">Guardar cambios</button></div>
        </div>

        <!-- <div class="row form-group">
          <label for="fondeador" class="col-form-label col-sm-2">Fondeador</label>
          <div class="col-sm-10">
            <select v-model="fondeador" name="fondeador" id="fondeador" class="form-control">
              <option v-for="opcion in catalogo_fondeadores.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div> -->
        <div class="row form-group">
          <label for="tipo_garantia" class="col-form-label col-sm-2">Tipo de garantía</label>
          <div class="col-sm-10">
            <select v-model="tipo_garantia" name="tipo_garantia" id="tipo_garantia" class="form-control">
              <option v-for="opcion in catalogo_garantias.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="folio" class="col-form-label col-sm-2">Número de fideicomiso</label>
          <div class="col-sm-10">
            <input v-model="folio" type="text" name="" id="" class="form-control">
          </div>
        </div>

        <div class="row form-group">
          <label for="numero_referencia" class="col-form-label col-sm-2">Número de Referencia</label>
          <div class="col-sm-10">
            <input v-model="numero_referencia" type="text" name="" id="" class="form-control">
          </div>
        </div>

        <div class="row form-group">
          <label for="fecha_disposicion" class="col-form-label col-sm-2">Fecha de disposición</label>
          <div class="col-sm-10"><input type="date" v-model="fecha_disposicion" name="fecha_disposicion" id="fecha_disposicion" class="form-control"></div>
        </div>

        <div class="row form-group">
          <label for="proveedor_opinion_valor" class="col-form-label col-sm-2">Proveedor de opinión de valor</label>
          <div class="col-sm-10">
            <select v-model="proveedor_opinion_valor" name="proveedor_opinion_valor" id="proveedor_opinion_valor" class="form-control">
              <option v-for="opcion in catalogo_proveedor_opinion_valor.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>

        <template v-if="solicitud.estatus == 0">
          <div class="row">
            <h1 class="col-sm-12">No viable</h1>
          </div>

          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Usuario</label>
            <div class="col-sm-10">
              <div class="form-control">{{ solicitud.usuario_inviable ? solicitud.usuario_inviable : 'No aplica' }}</div>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Fecha</label>
            <div class="col-sm-10">
              <div class="form-control">{{ solicitud.solicitud_inviable_at ? $moment(solicitud.solicitud_inviable_at).format('dddd DD MMMM YYYY HH:mm:ss') : 'No aplica' }}</div>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Razón</label>
            <div class="col-sm-10">
              <div class="form-control">{{ solicitud.razon_inviable ? solicitud.razon_inviable : 'No aplica' }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogos from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            estatus: 0
            ,usuarios: []
          }
        }
      }
    }
    ,data() {
      return {
        catalogo_fondeadores: {
          opciones: []
        }
        ,catalogo_garantias: {
          opciones: []
        }
        ,catalogo_proveedor_opinion_valor: {
          opciones: []
        }
        ,fondeador: null
        ,tipo_garantia: null
        ,folio: null
        ,fecha_disposicion: null
        ,proveedor_opinion_valor: null
        ,numero_referencia: null
      }
    }
    ,mounted() {
      this.cargar_catalogos();
      if (this.solicitud.fondeador)
        this.fondeador = this.solicitud.fondeador;
    }

    ,watch: {
      solicitud: {
        handler: function (val, oldVal) {
          if (this.solicitud.fondeador)
            this.fondeador = this.solicitud.fondeador;
        }
      }
    }
    ,updated() {
      if (this.solicitud.tipo_garantia)
        this.tipo_garantia = this.solicitud.tipo_garantia;

      if (this.solicitud.folio)
        this.folio = this.solicitud.folio;

      if (this.solicitud.fecha_disposicion)
        this.fecha_disposicion = this.solicitud.fecha_disposicion;

      if (this.solicitud.proveedor_ov)
        this.proveedor_opinion_valor = this.solicitud.proveedor_ov;

      if (this.solicitud.numero_referencia)
        this.numero_referencia = this.solicitud.numero_referencia;
    }
    ,methods: {
      buscar_usuario(tipo) {
        let usuario = null;

        this.solicitud.usuarios.forEach(usr => {
          if (usr.tipo == tipo)
            usuario = usr;
        })

        return usuario;
      }
      ,estatus() {
        switch(this.solicitud.estatus) {
          case 0:
            return 'No viable';
          case 1:
            return 'Activo';
          case 2:
            return 'Pausado'
        }
      }
      ,async cargar_catalogos() {
        try {
          this.catalogo_fondeadores = await this.$helper.cache_store('formalizacion.catalogos.fondeadores', async () => {
            return (await apiCatalogos.buscar_catalogo('fondeadores')).data;
          });

          this.catalogo_garantias = await this.$helper.cache_store('formalizacion.catalogos.garantias', async () => {
            return (await apiCatalogos.buscar_catalogo('tipo_garantia')).data;
          });

          this.catalogo_proveedor_opinion_valor = await this.$helper.cache_store('formalizacion.catalogos.proveedor_opinion_valor', async () => {
            return (await apiCatalogos.buscar_catalogo('proveedor_ov')).data;
          });
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async guardar_fondeador() {
        try {
          let payload = {
            fondeador: this.fondeador
            ,tipo_garantia: this.tipo_garantia
            ,folio: this.folio
            ,fecha_disposicion: this.fecha_disposicion
            ,proveedor_opinion_valor: this.proveedor_opinion_valor
          }

          payload = this.$helper.clear_null_nodes(payload);

          let res = (await apiSolicitudes.editar_solicitud(this.solicitud.id, payload));

          this.$log.info('res', res);
          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,computed: {
      fondeador_compuesto() {
        let nombre;

        if (this.fondeador)
          for (let i=0; i<this.catalogo_fondeadores.opciones.length; i++) {
            if (this.catalogo_fondeadores.opciones[i].valor == this.fondeador) {
              nombre = this.catalogo_fondeadores.opciones[i].nombre;
              i=this.catalogo_fondeadores.opciones.length;
            }
          }

        return nombre;
      }
    }
  }
</script>