<template>
  <div>
    <div class="row mt-4 mb-4">
      <h1 class="col-sm-12 text-right">Solicitud {{ solicitud.solicitud}}</h1>
    </div>



    <template v-if="slas_administrador_activas">
      <Step css_class="mb-4" :pasos="pasos" :activo="etapa" title="ETAPAS" :max_width="true" v-if="!solicitud_asignada" />

      <div v-if="!solicitud_asignada">
        <h2 class="col-sm-12" v-if="!solicitud_asignada && etapa_arribo">Documentación - Llegada</h2>
        <h2 class="col-sm-12" v-if="!solicitud_asignada && etapa === 'documentacion_validacion'">Documentación - Validación</h2>
        <h2 class="col-sm-12" v-if="ver_asignacion">Asignación de usuarios</h2>
      </div>
    </template>

    <div class="row mb-3">
      <div class="col-sm-12 text-right">
        <button class="btn btn-info mr-2" @click="obtener_solicitud">Recargar solicitud</button>

        <div class="btn-group mr-2" role="group" v-if="ver_detalle">
          <button id="grpo_estatus" type="button" :class="clases_boton_estatus(solicitud.estatus)" data-toggle="dropdown" aria-expanded="false">
            Estatus actual "{{ mostrar_estatus(solicitud.estatus) }}"
          </button>
          <div class="dropdown-menu" aria-labelledby="grpo_estatus">
            <a class="dropdown-item" href="#" @click.prevent="actualizar_estatus(1)">Activar solicitud</a>
            <a class="dropdown-item" href="#" @click.prevent="actualizar_estatus(2)">Pausar solicitud</a>
            <a class="dropdown-item" href="#" @click.prevent="actualizar_estatus(0)">No viable</a>
          </div>  
        </div>
        <template v-if="seccion == 'fyc'">
          <div class=" btn btn-primary" @click="modal_asignar_fondeador=true"> Enviar a Fondeador </div>
        </template>

      </div>
    </div>

    <div class="accordion" id="secciones_solicitudes">
      <div class="row mb-3" v-if="ver_detalle">
        <div class="col-sm-12">
          <ul class="nav nav-tabs solicitud_secciones">
            <li class="nav-item">
              <a class="nav-link active" href="#" @click.prevent="mostrar_seccion('info',$event)">Info</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="mostrar_seccion('slas',$event)">Sla's</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="mostrar_seccion('asignacion',$event)">Asignación</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="mostrar_seccion('documentacion',$event)">Documentación</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="mostrar_seccion('observaciones',$event)">Observaciones</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="mostrar_seccion('avaluo',$event)">Avaluo</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="mostrar_seccion('dictamenes',$event)">Incidencias</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="mostrar_seccion('revisiones',$event)">Revisiones</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="mostrar_seccion('comisiones_fondeador',$event)">Comisiones Fondeador</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="mostrar_seccion('fyc',$event)">F y C</a>
            </li>            
          </ul>
        </div>
      </div>

      <div v-if="seccion == 'info' && ver_detalle" class="row" ref="slas">
        <div class="col-sm-12">
          <Informacion :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'slas' && ver_detalle" class="row" ref="slas">
        <div class="col-sm-12">
          <Sla :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'asignacion' && ver_detalle" class="row" ref="asignacion">
        <div class="col-sm-12">
          <Asignacion :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="ver_documentos" class="row" ref="documentacion">
        <div class="col-sm-12">
          <Documento :solicitud="solicitud" @update="obtener_solicitud"  ref="documentoComponent"/>
        </div>
      </div>

      <div v-if="seccion == 'observaciones' && ver_detalle" class="row" ref="observaciones">
        <div class="col-sm-12">
          <Observacion :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'avaluo' && ver_detalle" class="row" ref="avaluo">
        <div class="col-sm-12">
          <Avaluo :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'dictamenes' && ver_detalle" class="row" ref="dictamenes">
        <div class="col-sm-12">
          <Dictamen :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'revisiones' && ver_detalle" class="row" ref="revisiones">
        <div class="col-sm-12">
          <Revisiones :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'comisiones_fondeador'" class="row" ref="comisiones_fondeador">
        <div class="col-sm-12">
          <ComisionesFondeadores :solicitud="solicitud" @update="obtener_solicitud" />
        </div>
      </div>

      <div v-if="seccion == 'fyc'" class="row" ref="fyc">
        <div class="col-sm-12">
          <Fyc :solicitud="solicitud" @close="cerrar_modal_asignar_fondeador"/>
        </div>
      </div>      

    </div>

    <SolicitudInviable v-if="modal_inviable" :solicitud="solicitud" @update="actualizar_cerrar_modal" @close="modal_inviable=false" />

    <div v-if="ver_asignacion" class="row" ref="asignacion">
      <div class="col-sm-12">
        <AsignacionUsuarios :solicitud="solicitud" @update="obtener_solicitud" ref="asignacionUsuariosComponent" />
      </div>
    </div>

    <div class="row mt-4 mb-5" v-if="slas_administrador_activas">
      <div class="col-sm-12 text-right">

        <div v-if="documentos_en_validacion" class="text-center">
          <hr>

          <div class="alert alert-primary" role="alert" v-if="etapa === 'documentacion_validacion'">
            La documentación de la solicitud se encuentra en validación de los siguientes comentarios: <br>
            <strong>{{solicitud.documentacion_validacion_razon_no_procede}}</strong>
          </div>

          <h5 for="validacion_documentacion">Validación de documentos</h5>
          <button type="button" class="btn btn-danger ml-3"  @click="open_modal_no_procede">No procede</button>
          <button type="button" class="btn btn-primary ml-3" @click="actualizar_etapa_sistema(true)">Procede</button>
        </div>

        <div v-if="ver_asignacion">
          <button class="btn btn-warning mr-2" v-if="!documentos_en_validacion" @click="open_modal_no_procede">Retroceder etapa</button>
          <button class="btn btn-success" v-if="!solicitud_asignada" @click="actualizar_etapa_sistema(true)">Guardar y avanzar</button>
        </div>
      </div>
    </div>

    <Modal :options="{width:'40vw',type:'danger'}" @close="modal_no_procede = false" v-if="modal_no_procede">
      <form @submit.prevent="accion_no_procede">
        <div class="title">Razon {{ documentos_en_validacion ? 'por la que no procede' : 'del retroceso' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="retroceso" class="col-form-label col-sm-3">Razón</label>
            <div class="col-sm-9">
              <textarea v-model="comentarios_no_procede" class="form-control" name="comentarios_no_procede" id="comentarios_no_procede" cols="30" rows="10"></textarea>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Confirmar acción</button>
              <button class="btn btn-danger" type="button" @click="modal_no_procede = false">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
    <AsignarFondeador v-if="modal_asignar_fondeador" :solicitud="solicitud" @close="cerrar_modal_asignar_fondeador"/>

  </div>
</template>

<script type="text/javascript">
  import Informacion from './Detalle/Informacion'
  import Documento from './Detalle/Documento'
  import Sla from './Detalle/Sla'
  import Asignacion from './Detalle/Asignacion'
  import Observacion from './Detalle/Observacion'
  import Avaluo from './Detalle/Avaluo'
  import Dictamen from './Detalle/Dictamen'
  import Revisiones from './Detalle/Revision'
  import SolicitudInviable from './../../../Componentes/SolicitudInviable'
  import Step from '@/components/Step'
  import ComisionesFondeadores from './Detalle/ComisionesFondeador'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
  import Modal from '@/components/Modal'
  import AsignacionUsuarios from "../../../Componentes/AsignacionUsuarios.vue";
  import etapa from "./Etapa.vue";
  import AsignarFondeador from "./Detalle/Fyc/AsignarFondeador";

  import api_catalogos from "@/apps/formalizacion/api/v2/catalogos.js";
  import app_config from "@/apps/formalizacion/config.js";

  import Fyc from "../Administracion/Detalle/Fyc";

  export default {
    components: {
      AsignacionUsuarios,
      Informacion, Sla, Documento, Asignacion, Observacion, Avaluo, Dictamen, SolicitudInviable, Revisiones,  Step, Modal,
      ComisionesFondeadores, Fyc, AsignarFondeador
    }
    ,data() {
      return {
        solicitud: {
          solicitud: null
          ,observaciones: []
          ,usuarios: []
        }
        ,modal_documentos: false
        ,modal_observaciones: false
        ,modal_reasignacion: false
        ,seccion_display: 'slas'
        ,modal_inviable: false
        ,modal_no_procede: false
        ,comentarios_no_procede: null
        ,solicitud_cargada: false
        ,retroceder_etapa: false
        ,seccion: 'info'
        ,etapa: ''
        ,pasos: [
          {
            texto: "Formalización arribo"
            ,valor: "inicio"
          },
          {
            texto: "Documentación validación"
            ,valor: "documentacion_validacion"
          }
          ,{
            texto: "Asignación"
            ,valor: "asignacion_usuarios"
          }
          ,{
            texto: "Finalización administración"
            ,valor: "finalizacion_administracion"
          }
        ]
        ,config:null
        ,fondeadores:[]
        ,fondeador:null
        ,modal_asignar_fondeador:false
      }
    }
    ,computed:{
      slas_administrador_activas(){
        return (this.solicitud.slas_administrador_activas);
      }
      ,etapa_arribo(){
        return (this.etapa === 'inicio' || this.etapa === 'formalizacion_arribo');
      }
      ,documentos_en_validacion (){
        return (this.etapa === 'documentacion_validacion' || this.etapa_arribo);
      }
      ,solicitud_asignada(){
        return this.etapa === 'finalizacion_administracion';
      }
      ,ver_documentos(){
        return (this.documentos_en_validacion && this.slas_administrador_activas) || (this.solicitud_asignada && this.seccion === 'documentacion') || (!this.slas_administrador_activas && this.seccion === 'documentacion')
      }

      ,ver_asignacion(){
        return (this.etapa === 'asignacion_usuarios')
      }
      ,ver_detalle(){
        return (this.solicitud_asignada && this.slas_administrador_activas) || !this.slas_administrador_activas;
      },
      estatus_solicitud_fyc(){
        switch (this.solicitud.estatus_solicitud_cofinanciamiento) {
          case 1:
            return 'En proceso de votación ';

          case 2:
            return 'Aceptada ';            
          
          case 3:
            return 'Rechazada ';
        }
      },
      texto_boton_enviar_solicitud_fyc(){
        switch (this.solicitud.estatus_solicitud_cofinanciamiento) {
          case 0:
            return 'Asignar Fondeador';            
          
          case 3:
            return 'Enviar a otro Fondeador';
        }        
      },
      // obtener_nombre_fondeador(){
      //   this.fondeadores.forEach(fondeador =>{
      //     if(this.solicitud.fondeador == fondeador.valor);
      //       return fondeador.nombre;
      //   });
      // }
    }
    ,mounted() {
      this.config = app_config[this.$helper.getEnv()];
      this.obtener_solicitud();
      this.obtener_catalogos();

      setTimeout(() => {
        this.obtener_nombre_fondeador();
      }, 200);

    }
    ,methods: {
      async obtener_solicitud() {
        try {
          this.$log.info('cargamos solicitud');
          this.solicitud = (await apiSolicitudes.obtener_solicitud(this.$route.params.id)).data;
          this.$log.info('solicitud mostrada', this.solicitud);
          if (this.solicitud.sla.length > 0) {
            let ultimo_sla = this.obtener_ultima_etapa_activa(this.solicitud.sla);
            this.$log.info('ultimo_sla: ', ultimo_sla);

            if (ultimo_sla.length > 0)
              this.etapa = ultimo_sla[ultimo_sla.length-1].tipo;
          }

          if (this.etapa === 'formalizacion_arribo'){
            this.etapa = 'inicio';
          }

          this.solicitud_cargada = true;

        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,mostrar_seccion(seccion, e) {
        this.seccion = seccion;

        let contenedor = document.querySelector('.solicitud_secciones');
        let links = contenedor.querySelectorAll('.nav-link');

        links.forEach(link => {
          if (link.classList.contains('active'))
            link.classList.remove('active');
        })

        e.target.classList.add('active');

        // if (e.target.classList.contains('active'))
        //   return;

        // let contenedor = document.querySelector('.solicitud_secciones');
        // let links = contenedor.querySelectorAll('.nav-link');

        // links.forEach(link => {
        //   if (link.classList.contains('active'))
        //     link.classList.remove('active');
        // })

        // e.target.classList.add('active');

        // if (!this.$refs[seccion])
        //   return this.$helper.showMessage('Error','No existe la sección '+seccion,'error','alert');

        // this.$refs[seccion].classList.remove('hidden');
        // this.$refs[this.seccion_display].classList.add('hidden');
        // this.seccion_display = seccion;
      }
      ,async actualizar_estatus(estatus) {
        try {
          if(estatus == 0)
            return this.modal_inviable = true;

          let res = (await apiSolicitudes.editar_solicitud(this.solicitud.id, {estatus})).data;

          this.obtener_solicitud();
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,mostrar_estatus(estatus) {
        switch(estatus) {
          case 0:
            return 'No viable';
          case 1:
            return 'Activa';
          case 2:
            return 'Pausada';
        }
      }
      ,clases_boton_estatus(estatus) {
        let clase = 'btn btn-';

        switch(estatus) {
          case 0:
            clase = clase+'danger';
            break;
          case 1:
            clase = clase+'success';
            break;
          case 2:
            clase = clase+'warning';
        }

        clase = clase+' dropdown-toggle';

        return clase;
      }
      ,actualizar_cerrar_modal() {
        this.modal_inviable = false;
        this.obtener_solicitud();
      }
      ,obtener_ultima_etapa_activa(etapas) {
        let etapas_activas = []

        etapas.forEach(etapa => {
          if (etapa.tipo_usuario == 0 && etapa.estatus == 1)
            etapas_activas.push(etapa);
        })

        return etapas_activas;
      }
      ,open_modal_no_procede(){
        this.comentarios_no_procede = null;
        this.modal_no_procede = true;
      }
      ,accion_no_procede(){
        if(this.documentos_en_validacion)
         this.actualizar_etapa_sistema(false)
        else
          this.retroceder_etapa_sistema()
      }
      ,async actualizar_etapa_sistema(procede){

        let payload = {procede: procede}

        if (!procede && this.documentos_en_validacion){
          payload['comentarios'] = this.comentarios_no_procede
        }

        if (this.ver_asignacion){
          if (!this.$refs.asignacionUsuariosComponent.usuario_valuador.id || !this.$refs.asignacionUsuariosComponent.usuario_notario.id){
            this.$helper.showMessage('Error', 'No se puede avanzar de etapa, debe asignar un valuador y notario para poder avanzar.', 'error', 'alert');
            return;
          }else{
            await this.$refs.asignacionUsuariosComponent.actualizar_usuarios()
          }
        }

        let res = (await apiSolicitudes.actualizar_etapa_sistema(this.solicitud.id, payload)).data;
        if(!res.success){
          const error = {
            error: !res.error ? 'Error al avanzar de etapa' : res.error,
            message: !res.message ? 'Ocurrio un error inesperado, vuelva a intentar mas tarde' : res.message
          }
          this.$helper.showMessage(error.message, error.error, 'error', 'alert');
        }else{
          if (this.modal_no_procede){
            this.modal_no_procede = false
          }

          this.obtener_solicitud()
        }
         
      }

      ,async retroceder_etapa_sistema(){
        let payload = {comentarios:  this.comentarios_no_procede}
        let res = (await apiSolicitudes.retroceder_etapa_sistema(this.solicitud.id, payload)).data;
        if(!res.success){
          const error = {
            error: !res.error ? 'Error al retroceder de etapa' : res.error,
            message: !res.message ? 'Ocurrio un error inesperado, vuelva a intentar mas tarde' : res.message
          }
          this.$helper.showMessage(error.message, error.error, 'error', 'alert');
        }else{
          if (this.modal_no_procede){
            this.modal_no_procede = false
          }
        }

        this.obtener_solicitud()
      },
      async cerrar_modal_asignar_fondeador(){
        this.modal_asignar_fondeador=false;
          this.modal_asignar_fondeador=false;
        setTimeout(() => {
          this.obtener_solicitud();
        }, 200);
      },
      async obtener_catalogos(){
        try {
          this.fondeadores  = (await api_catalogos.obtener_catalogo(this.config.catalogos.fondeadores)).data.opciones;
          // this.$log.info('fondeadores', this.fondeadores);
        } catch (error) {
            this.$log.info('Error',error);
            this.$helper.showAxiosError(error,'Error');
        }

      },
      obtener_nombre_fondeador(){
        this.fondeadores.forEach(fondeador => {
          if(this.solicitud.fondeador === fondeador.valor)
            this.fondeador = fondeador.nombre;
        });
      },            
    },
    watch:{
      solicitud:{
        handler(){
          this.obtener_nombre_fondeador();
        },
        deep:true,
        }
    }
  }
</script>

<style lang="scss" scoped>
  .visible {
    display: flex;
  }

  .hidden {
    display: none !important;
  }
</style>