<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
          <div class="row usuario mb-4">
            <div class="col-sm-4 tipo"><div>Valuador</div></div>
            <div class="col-sm-8 nombre"><div>{{ usuario_valuador.nombre }}</div></div>
          </div>
          <div class="row usuario">
            <div class="col-sm-4 tipo"><div>Notario</div></div>
            <div class="col-sm-8 nombre"><div>{{ usuario_notario.nombre }}</div></div>
          </div>

          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2" @click="reasignar_solicitud">Re-asignar</button>
            </div>
          </div>
        </div>
    </div>

    <Modal v-if="reasignar" :options="{width: '40vw'}" @close="cancelar_reasignacion">
      <form @submit.prevent="actualizar_usuarios">
        <div class="title">Re-asignar</div>
        <div class="body">
          <div class="row form-group">
            <label for="reasignar_valuador" class="col-form-label col-sm-3">Valuador</label>
            <div class="col-sm-9">
              <select v-model="reasingacion.valuador" name="reasignar_valuador" id="reasignar_valuador" class="form-control">
                <option v-for="usuario in usuarios_valuadores" :key="usuario.id" :value="usuario.id">{{ usuario.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <label for="reasignar_notario" class="col-form-label col-sm-3">Notario</label>
            <div class="col-sm-9">
              <select v-model="reasingacion.notario" name="reasignar_notario" id="reasignar_notario" class="form-control">
                <option v-for="usuario in usuarios_notarios" :key="usuario.id" :value="usuario.id">{{ usuario.nombre }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Re-asginar</button>
              <button class="btn btn-danger" type="button" @click="cancelar_reasignacion">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
  import apiUsuarios from '@/apps/formalizacion/api/v2/usuarios'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            usuarios: []
          }
        }
      }
    }
    ,data() {
      return {
        reasignar: false
        ,reasingacion: {
          valuador: null
          ,notario: null
        }
        ,usuarios: []
      }
    }
    ,mounted() {
      this.$log.info('solicitud', this.solicitud);
    }
    ,methods: {
      async obtener_usuarios() {
        try {
          if (this.usuarios.length > 0) {
            this.reasingacion.valuador = this.usuario_valuador.id;
            this.reasingacion.notario = this.usuario_notario.id;

            return;
          }

          this.usuarios = (await apiUsuarios.obtener_usuarios()).data;

          this.reasingacion.valuador = this.usuario_valuador.id;
          this.reasingacion.notario = this.usuario_notario.id;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,reasignar_solicitud() {
        if (!this.solicitud.documentacion_viable_at)
          return this.$helper.showMessage('Error','Debes primero marcar como viable la solicitud en el área de Documentación','error','alert');

        this.obtener_usuarios();
        this.reasignar = true;
      }
      ,async actualizar_usuarios() {
        try {
          let res = (await apiSolicitudes.reasignar_solicitud(this.solicitud.id, this.reasingacion)).data;
          this.$log.info('res', res);

          this.$emit('update');
          this.$emit('close');
          this.cancelar_reasignacion();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cancelar_reasignacion() {
        this.reasignar = false;
        this.reasingacion.valuador = this.usuario_valuador.id;
        this.reasingacion.notario = this.usuario_notario.id;
      }
    }
    ,computed: {
      usuario_valuador() {
        let usuario = {
          nombre: 'No viable'
        };

        for(let i=0; i<this.solicitud.usuarios.length; i++) {
          if (this.solicitud.usuarios[i].tipo == 1) {
            usuario = this.solicitud.usuarios[i];
            i=this.solicitud.usuarios.length;
          }
        }

        return usuario;
      }
      ,usuario_notario() {
        let usuario = {
          nombre: 'No viable'
        };

        for(let i=0; i<this.solicitud.usuarios.length; i++) {
          if (this.solicitud.usuarios[i].tipo == 2) {
            usuario = this.solicitud.usuarios[i];
            i=this.solicitud.usuarios.length;
          }
        }

        return usuario;
      }
      ,usuarios_valuadores() {
        let usuarios = [];

        for(let i=0; i<this.usuarios.length; i++) {
          if (this.usuarios[i].tipo == 1)
            usuarios.push(this.usuarios[i]);
        }

        return usuarios;
      }
      ,usuarios_notarios() {
        let usuarios = [];

        for(let i=0; i<this.usuarios.length; i++) {
          if (this.usuarios[i].tipo == 2)
            usuarios.push(this.usuarios[i]);
        }

        return usuarios;
      }
    }
    ,watch: {
      solicitud: {
        handler() {
          this.$log.info('solicitud update');
          this.reasingacion.valuador = this.usuario_valuador.id;
          this.reasingacion.notario = this.usuario_notario.id;
        }
        ,deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .usuario {
    .tipo {
      div {
        background-color: #666666;
        color: #fff;
        padding: 5px 10px;
      }
    }

    .nombre {
      div {
        // background-color: #999999;
        padding: 5px 10px;
      }
    }
  }
</style>