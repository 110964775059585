<template>
  <div>
    <Modal :options="{width: '50vw', close: true}" @close="$emit('close')">
        <div class="title"> Selecciona un Fondeador</div>
        <div class="body">
            <div class="row form-group">
                <label for="estado" class="col-form-label col-sm-3">Fondeadores </label>
                <div class="col-sm-9">
                    <select v-model="fondeador_seleccionado.fondeador_codigo" name="fondeador.nombre" id="fondeador.nombre" class="form-control" >
                        <option value="null">Selecciona un fondeador</option>
                        <option v-for="fondeador in fondeadores" :key="fondeador.id" :value="fondeador.valor">{{ fondeador.nombre }}</option>
                    </select>
                </div>
            </div>            
            <div v-if="fondeador_seleccionado.fondeador_codigo">
                <div class="row form-group">
                    <label for="estado" class="col-form-label col-sm-3">Tipo de fondeo </label>
                    <div class="col-sm-9">
                        <select v-model="fondeador_seleccionado.tipo_fondeo" name="fondeador.tipo_fondeo" id="fondeador.tipo_fondeo" class="form-control" >
                            <option value="null">Selecciona un tipo de fondeo</option>
                            <option v-for="tipo_fondeo in tipos_fondeo" :key="tipo_fondeo.id" :value="tipo_fondeo.valor">{{ tipo_fondeo.nombre }}</option>
                        </select>    
                    </div>
                </div>  
            </div>
            <div v-if="fondeador_seleccionado.tipo_fondeo == 'cofinanciado'">
                <div class="row form-group">
                    <label for="delegacion" class="col-form-label col-sm-3">Ingrese el porcentaje del fondeador </label>
                    <div class="col-sm-9"><input v-model="fondeador_seleccionado.porcentaje_fondeo" type="number" name="fondeador.porcentaje_fondeo" id="fondeador.porcentaje_fondeo" class="form-control"></div>  
                </div>                  
            </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
                <button type="button" class="btn btn-primary mr-2" @click="asignar_fondeador"> Asignar Fondeador </button>
                <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
    </Modal>
  </div>
</template>

<script>

import Modal from '@/components/Modal';
import api_catalogos from "@/apps/formalizacion/api/v2/catalogos.js";
import app_config from "@/apps/formalizacion/config.js";
import api_cofinanciamiento from "@/apps/formalizacion/api/v2/cofinanciamiento.js";
import api_solicitudes from '@/apps/formalizacion/api/v2/solicitudes';

export default {
  components: {
    Modal
  },
  props:{
    solicitud:null
  },
  data() {
    return {
        config:null,
        fondeadores:[],
        fondeador_seleccionado:{
          id:null,
          solicitud_id:null,
          fondeador_codigo:null,
          tipo_fondeo:null,
          porcentaje_fondeo:null,
        },

        tipos_fondeo:[],
    }
  },
  mounted(){
    this.config = app_config[this.$helper.getEnv()];
    this.obtener_catalogos();
  },
  methods:{
    async obtener_catalogos(){
      try {
        this.fondeadores  = (await api_catalogos.obtener_catalogo(this.config.catalogos.fondeadores)).data.opciones;
        this.tipos_fondeo  = (await api_catalogos.obtener_catalogo(this.config.catalogos.tipo_fondeo)).data.opciones; 
        // this.$log.info('fondeadores', this.tipos_fondeo);
      } catch (error) {
          this.$log.info('Error',error);
          this.$helper.showAxiosError(error,'Error');
      }

    },
    async asignar_fondeador(){
      this.fondeador_seleccionado.id = this.solicitud.id;
      this.fondeador_seleccionado.solicitud_id = this.solicitud.solicitud;
      
      if(this.fondeador_seleccionado.tipo_fondeo == 'directo')
        this.fondeador_seleccionado.porcentaje_fondeo = 100;      
      
      try {
        if(this.fondeador_seleccionado.fondeador_codigo == null || this.fondeador_seleccionado.solicitud_id == null || this.fondeador_seleccionado.id == null)
          return this.$helper.showMessage('Error','Debes serleccionar un fondeador', 'error','alert');

        if(this.fondeador_seleccionado.tipo_fondeo == 'cofinanciado' && this.fondeador_seleccionado.porcentaje_fondeo == null || this.fondeador_seleccionado.porcentaje_fondeo == '')
          return this.$helper.showMessage('Error','Debes indicar el porcentaje del fondeo', 'error','alert');

        await api_cofinanciamiento.enviar_solicitud(this.fondeador_seleccionado);
        await api_solicitudes.editar_solicitud(this.solicitud.id, {fondeador:this.fondeador_seleccionado.fondeador_codigo});

        // this.$log.info('solicitud id ', this.solicitud.id)
        this.$emit('close');

      } catch (error) {
        this.$log.info('Error',error);
        this.$helper.showAxiosError(error,'Error');
      }
    }
  }



}
</script>

<style lang="scss" scoped>

</style>