<template>

  <div class="row">
    <div class="col-sm-6 offset-sm-2">
      <div class="row usuario mb-4">
        <div class="col-sm-3 tipo"><div>Valuador</div></div>
        <div class="col-sm-8 nombre">
          <select v-model="usuario_valuador.id" name="reasignar_valuador" id="reasignar_valuador" class="form-control">
            <option v-for="usuario in usuarios_valuadores" :key="usuario.id" :value="usuario.id">{{ usuario.nombre }}</option>
          </select>
        </div>
      </div>
      <div class="row usuario">
        <div class="col-sm-3 tipo"><div>Notario</div></div>
        <div class="col-sm-8 nombre">
          <select v-model="usuario_notario.id" name="reasignar_notario" id="reasignar_notario" class="form-control">
            <option v-for="usuario in usuarios_notarios" :key="usuario.id" :value="usuario.id">{{ usuario.nombre }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
import apiUsuarios from '@/apps/formalizacion/api/v2/usuarios'

export default {
  components: {
  }
  ,props: {
    solicitud: {
      type: Object
      ,default() {
        return {
          usuarios: []
        }
      }
    }
  }
  ,data() {
    return {
      valuador: null
      ,notario: null
      ,usuarios: []
    }
  }
  ,mounted() {
    this.$log.info('solicitud', this.solicitud);
    this.obtener_usuarios();
  }
  ,methods: {
 async obtener_usuarios() {
      try {
        this.usuarios = (await apiUsuarios.obtener_usuarios()).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,async actualizar_usuarios() {
      try {
        let payload = {
          notario: this.usuario_notario.id,
          valuador: this.usuario_valuador.id
        }
        let res = (await apiSolicitudes.reasignar_solicitud(this.solicitud.id, payload)).data;
        this.$log.info('actualizar_usuarios res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
  ,computed: {
    usuarios_valuadores() {
    let usuarios = [];
    for(let i=0; i<this.usuarios.length; i++) {
      if (this.usuarios[i].tipo == 1)
        usuarios.push(this.usuarios[i]);
    }
    return usuarios;
  }
  ,usuarios_notarios() {
    let usuarios = [];
    for(let i=0; i<this.usuarios.length; i++) {
      if (this.usuarios[i].tipo == 2)
        usuarios.push(this.usuarios[i]);
    }
    return usuarios;
    }
    ,usuario_valuador() {
      let usuario = {
        nombre: 'No viable'
      };

      for(let i=0; i<this.solicitud.usuarios.length; i++) {
        if (this.solicitud.usuarios[i].tipo == 1) {
          usuario = this.solicitud.usuarios[i];
          i=this.solicitud.usuarios.length;
        }
      }
      return usuario;
    }
    ,usuario_notario() {
      let usuario = {
        nombre: 'No viable'
      };

      for(let i=0; i<this.solicitud.usuarios.length; i++) {
        if (this.solicitud.usuarios[i].tipo == 2) {
          usuario = this.solicitud.usuarios[i];
          i=this.solicitud.usuarios.length;
        }
      }

      return usuario;
    }
  }
  ,watch: {/**
    solicitud: {
      handler() {
        this.$log.info('solicitud update');
        this.solicitud.valuador = this.usuario_valuador.id;
        this.solicitud.notario = this.usuario_notario.id;
      }
      ,deep: true
    }**/
  }
}

</script>

<style lang="scss" scoped>
  textarea {
    resize: none;
  }
</style>